<!-- (c) 才酝通 -->
<template>
    <div id="content" class="container">
        <filter-box
            :categories1="scenes"
            :categories2="categories"
            :cid1="pagination.scene"
            :cid2="pagination.cid"
            @change="filterChange"
        ></filter-box>
        <template v-if="list.length > 0 || loading">
            <div class="gray"></div>
            <div class="topic-item" v-for="topic in list" :key="topic.id">
                <topic-item
                    :id="topic.id"
                    :thumb="topic.thumb"
                    :title="topic.title"
                    :view_times="topic.viewTimes"
                ></topic-item>
            </div>
            <div class="drop">
                <img v-show="loading && !over" src="../../assets/img/loading.gif" />
                <span v-if="over">已经到底了</span>
            </div>
        </template>
        <none v-else></none>
    </div>
</template>

<script>
import FilterBox from "../../components/filter-box.vue";
import TopicItem from "../../components/topic-item";
import None from "../../components/none.vue";


export default {
    components: {
        FilterBox,
        TopicItem,
        None
    },
    data() {
        return {
            scenes: [
                {
                    id: "",
                    name: "全部",
                },
                {
                    id: "free",
                    name: "免费",
                },
            ],
            list: [],
            pagination: {
                page: 1,
                size: 10,
                scene: "default",
                cid: 0,
            },
            categories: [],
            filterShowStatus: false,
            over: false,
            loading: false,
        }
    },
    beforeRouteLeave(to, from, next) {
        if (from.meta.keepAlive) {
            // 只针对 keep-alive 的页面记录滚动位置
            let $content = document.querySelector('#content'); // 列表的外层容器
            let scrollTop = $content ? $content.scrollTop : 0;
            from.meta.scrollTop = scrollTop;
        }
        next();
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'TopicDetail') {
            //判断是从哪个路由过来的，若是detail页面不需要刷新获取新数据，直接用之前缓存的数据即可
            to.meta.useCache = true;
        }else {
            to.meta.useCache = false;
        }
        next()
    },
    activated() {
        if(!this.$route.meta.useCache){
            window.addEventListener("scroll", this.ScrollToBottomEvt, true);
            console.log('不是缓存')
            this.list = [];
            this.pagination.cid = 0;
            this.pagination.page = 1;
            this.pagination.scene = "default";
            this.over = false;
            this.getData();
            this.getCategories();
        }else{
            console.log('此时我们读缓存')
            window.addEventListener("scroll", this.ScrollToBottomEvt, true);
        }
        let scrollTop = this.$route.meta.scrollTop;
        if (scrollTop && scrollTop !== 0) {
            // 初次进入页面不执行，返回页面执行
            let $content = document.querySelector('#content');
            if ($content) {
                this.$nextTick(() => {
                $content.scrollTop = scrollTop;
                })
            }
        }
    },
    mounted() {
        window.addEventListener("scroll", this.ScrollToBottomEvt, true);
        // this.getData();
        // this.getCategories();
    },
    beforeDestroy() {
        window.removeEventListener("scroll", this.ScrollToBottomEvt, true);
    },
    deactivated() {
        window.removeEventListener("scroll", this.ScrollToBottomEvt, true);
    },
    methods: {
        getCategories(){
            this.$api.Topic.Categories().then((res) => {
                this.categories = res.data;
            });
        },
        ScrollToBottomEvt() {
            const el = document.getElementById("content");
            const toBottom = el.scrollHeight - window.screen.height - el.scrollTop;
            if (toBottom < 10) {
                this.getData(true);
            }
        },
        filterChange(scene, cid) {
            this.pagination.scene = scene;
            this.pagination.cid = cid;
            this.resetData();
            this.getData();
        },
        resetData() {
            this.list = [];
            this.pagination.page = 1;
            this.over = false;
        },
        getData(more = false) {
            if (this.over) {
                return;
            }
            if (this.loading) {
                return;
            }
            if (more) {
                this.pagination.page++;
            }
            this.loading = true;
            console.log(this.pagination)
            this.$api.Topic.List(this.pagination).then((res)=> {
                let list = res.data.records;
                if (list.length > 0) {
                    this.list.push(...list);
                }
                setTimeout(() => {
                    this.loading = false;
                    if (list.length < this.pagination.size) {
                        this.over = true;
                    }
                }, 200);
            });
        }
    }
}
</script>
<style lang="less" scoped>
.topic-item {
  width: 100%;
  height: auto;
  float: left;
  box-sizing: border-box;
  padding: 15px;
}
.gray {
  float: left;
  width: 100%;
  height: 10px;
  background: #f3f6f9;
}
.drop {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #cccccc;
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>

<style lang="less">
#page {
  background-color: white;
}
</style>
